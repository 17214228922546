import React, {useEffect, useState} from 'react';
import "../css/serviceCenter.css";
import icon_page_first from "../images/servicecenter_img/Icon-page_first.png";
import icon_page_last from "../images/servicecenter_img/Icon-page_last.png";
import axios from "axios";
import baseUrl from "../BaseUrl";
import $ from 'jquery';
import InGameServiceNoticesContent from "./serviceNoticesContent";


const getDivisionText = (division) => {

    switch (division) {
        case 0:
            return '[공지]';
        case 1:
            return '[이벤트]';
        case 2:
        case 3:
        case 4:
            return '[점검]';
        default:
            return '';
    }
};


const InGameServiceNotices = () => {
    const [notices, setNotices] = useState([]);
    const [noticesCount, setNoticesCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [searchNotices, setSearchNotices] = useState('');
    const [pageNumbers, setPageNumbers] = useState([]);
    const [noticeID, setNoticeID] = useState("");
    const [showContent, setShowContent] = useState(false);
    const [showPopup, setShowPopup] = useState(false);


    const handlePageChange = (page) => {
        if (page === 'next') {
            console.log();
            if (noticesCount > (offset + 1) * 5) {
                noticeData(offset + 1);
                setOffset(offset + 1);
                $('.qa_pagination_buttons').removeClass('qa_pagination_buttons');
                $(`#${offset + 1}`).addClass('qa_pagination_buttons');
            }
            else {
                setShowPopup(true);
            }
        }
        else {
            if (offset > 0 ) {
                noticeData(offset - 1);
                setOffset(offset - 1);
                $('.qa_pagination_buttons').removeClass('qa_pagination_buttons');
                $(`#${offset - 1}`).addClass('qa_pagination_buttons');
            }
            else {
                setShowPopup(true);
            }

        }
    };

    const handlePageClick = (index) => {
        noticeData(index);
        setOffset(index);
        $('.qa_pagination_buttons').removeClass('qa_pagination_buttons');
        $(`#${index}`).addClass('qa_pagination_buttons');
    };


    const serviceCenter = (noticeId) => {
        setShowContent(noticeId);
        setNoticeID(noticeId);
    };

    const noticeData = async (offset) => {
        try {
            const response = await axios.get(baseUrl + `service_center/notice/?offset=${offset}`);
            if (response.status === 200) {
                setNotices(response.data.data);
                setNoticesCount(response.data.count);

                const result = response.data.count / 5;
                setPageNumbers(Array(Math.round(result)).fill(result));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handelSearchNotice = (e) => {
        let inputVal = e.target.value.replace(/^\s+/, '').replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '');
        setSearchNotices(inputVal);
    };

    const searchNoticeData = async () => {
        if(!searchNotices){
            return;
        }

        try {
            const response = await axios.get(baseUrl + 'home/notice_search/?search='+searchNotices );
            if (response.status === 200) {
                setNotices(response.data.results);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        $('body').css({
            'background': 'black',
            'zoom': '110%'
        });

        noticeData(0);
    }, []);


    return (<>
            <div className="game_service_center_warp">
                <div className="game_service_game_center">
                    {!showContent && (
                        <div className="game_center_warp_inner">
                        <div className="game_sub_main_title_box">
                            <div className="subMaintitle">공지사항</div>

                            <div className="notice_search_box">
                                <div className="notice_input_box">
                                    <input
                                        type="text"
                                        id="SerchText"
                                        name="Serch"
                                        placeholder="검색어를 입력하세요"
                                        autoComplete="off"
                                        value={searchNotices}
                                        onChange={handelSearchNotice}
                                    />
                                </div>

                                <button type="button" className="notice_search_button" onClick={searchNoticeData}>
                                    검색
                                </button>

                            </div>
                        </div>


                        <div className="game_notice_contents_box">
                            {notices.map((notice) => (
                                <div
                                    key={notice.id}
                                    className="game_notice_contents"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => serviceCenter(notice.notice_id)}
                                >
                                    <div className="notice_contents_inner" style={{color: "white"}}>
                                        <div className="notice_contents_title">
                                            <em>{getDivisionText(notice.division)}{notice.title}</em>
                                        </div>
                                        <div className="notice_contents_admin">
                                            <em>{notice.admin}</em>
                                        </div>
                                        <div className="notice_contents_date">
                                            <em>{notice.date.split('T')[0]}</em>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <div className="game_notice_paging_box">

                            <div className="service_paging_button">
                                <div onClick={() => handlePageChange('prev')} style={{borderRight: '2px solid rgb(78, 78, 78)'}}>
                                    <img src={icon_page_first} alt=""/>
                                </div>
                            </div>

                            <div>
                                {pageNumbers.map((value, index) => {
                                    return (
                                        <>
                                            {index === 0 ?
                                                <button id={index} className="qa_pagination_buttons_2 qa_pagination_buttons" style={{color: "white"}} onClick={() => handlePageClick(index)}>
                                                    {index + 1}
                                                </button>
                                                :
                                                <button id={index} className="qa_pagination_buttons_2" style={{color: "white"}} onClick={() => handlePageClick(index)}>
                                                    {index + 1}
                                                </button>
                                            }
                                        </>

                                        )

                                })}
                            </div>

                            <div className="service_paging_button">
                                <div onClick={() => handlePageChange('next')} style={{borderLeft: '2px solid rgb(78, 78, 78)'}}>
                                    <img src={icon_page_last} alt=""/>
                                </div>
                            </div>

                        </div>
                    </div>
                    )}

                    {showContent && (
                        <InGameServiceNoticesContent noticeID={noticeID} setShowContent={setShowContent}/>
                    )}

                    {showPopup && (
                        <div className="game_service_center_popup">
                            <div className="game_notice_popup_content">
                                <div className="user_restrict_popup_div_3" style={{marginTop: "35px"}}>
                                    더이상 페이지가 없습니다.
                                </div>

                                <div className="ok_no_box_button" style={{paddingTop: "20px"}}>
                                    <button className="in_game_ok_no_button" style={{marginRight: "unset"}} onClick={() => setShowPopup(false)}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>
    );
};

export default InGameServiceNotices;
