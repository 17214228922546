import React, {useEffect, useState} from "react";
import $ from 'jquery';
import QAPage from "./QA";
import "../css/inGameServiceCenter.css";

function InGameSearchForHelp() {
    const [searchText, setSearchText] = useState('');
    const [showQA, setShowQA] = useState(false);
    const [qaPage, setQAPage] = useState('');
    const [selectedValue, setSelectedValue] = useState('');

    const handleValueChange = (event) => {
        setSelectedValue(event.target.value);
    };


    const handleSearchClick = () => {
        setShowQA(true);
        setQAPage(<QAPage gameName={'other'} searchTxt={searchText} searchDiv={selectedValue}/>);
    };

    function handleSearchTextChange(e) {
        let inputVal = e.target.value.replace(/^\s+/, '').replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '');
        setSearchText(inputVal);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    useEffect(() => {
        $('body').css({
            'background': 'black',
            'zoom': '110%'
        });

       setShowQA(true);
       setQAPage(<QAPage gameName={'sm_smpl'} searchTxt={''}/>);

    }, []);

    return (
        <>
            <div className="game_service_center_warp">
                <div className="game_service_game_center" style={{marginLeft: "95px"}}>
                    <div className="game_center_warp_inner">
                        <div className="game_sub_main_title_box">
                            <div className="subMaintitle" style={{color: "white"}}>도움말 검색</div>
                        </div>

                        <div className="qa_box">
                            <div className="qa_title" style={{color: "white"}}>
                                <em>도움말 검색</em>
                            </div>
                            <div className="qa_search_box">
                                <div className="search_box_drop_box">
                                    <select name="SerchDiv" className="drop_box_select" value={selectedValue}
                                            onChange={handleValueChange} style={{color: "white"}}>
                                        <option value="">전체</option>
                                        <option value="1">회원정보</option>
                                        <option value="2">유료/결제</option>
                                        <option value="3">신고/이용제한</option>
                                        <option value="5">서비스</option>
                                        <option value="6">게임문의</option>
                                    </select>
                                    <div className="qa_input_box">
                                        <input
                                            style={{color: "white"}}
                                            type="text"
                                            id="SerchText2"
                                            name="Serch"
                                            placeholder="검색어를 입력하세요"
                                            autoComplete="off"
                                            value={searchText}
                                            onChange={handleSearchTextChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="qa_search_img" onClick={handleSearchClick}>
                                <div className="qa_search_img_src"/>
                            </div>
                        </div>

                        {qaPage}
                    </div>

                </div>
            </div>
        </>

    );
}

export default InGameSearchForHelp