import React, {useEffect, useState} from "react";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";

const renderDivision = (division) => {
    switch (division) {
        case 0:
            return '[설치/실행]';
        case 1:
            return '[계정]';
        case 2:
            return '[결제]';
        case 3:
            return '[이벤트]';
        case 4:
            return '[전체]';
        case 5:
            return '[기타]';
        default:
            return '[알 수 없음]';
    }
};

const InGameManToManContent = ({requestId, setShowContent}) => {
    const [manToManContent, setManToManContent] = useState(false);

    const showContent = async () => {
        try {
            const response = await axios.get(baseUrl + 'service_center/customer_request_content/?request_id=' + requestId, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (response.status === 200) {
                setManToManContent(response.data[0]);
            }
        } catch (e) {

        }
    };


    useEffect(() => {
        showContent();
    }, [requestId]);

    return (
        <>
            <div className="game_service_center_warp">
                <div className="game_service_game_center" style={{marginLeft: "unset"}}>
                    <div className="game_center_warp_inner_content">
                        {manToManContent && (<div className="game_email_contents_box">
                            <div className="email_contents_date_box">
                                {manToManContent.writeDate.split('T')[0]}
                            </div>
                            <div className="game_email_contents_title_box">
                                <button
                                    className="quit_ok_no_button"
                                    style={{position: 'relative', margin: '27px 0 0 0'}}
                                >
                                    Q
                                </button>
                                <div style={{display: 'inline-block', marginTop: '27px', marginLeft: '5px'}}>
                                    <em style={{color: '#15a3e1', fontWeight: 'bold'}}>{manToManContent.title}</em>
                                    <span className="info_txt_bar"
                                          style={{backgroundColor: '#c0c0c0', height: '15px'}}></span>
                                    <em style={{
                                        color: 'white',
                                        fontSize: '16px',
                                        fontWeight: 'normal'
                                    }}>{renderDivision(manToManContent.division)}</em>
                                </div>
                            </div>
                            <div className="email_q_text_box" style={{ whiteSpace: 'pre-wrap', color:"white" }}>
                                {manToManContent.contents}
                            </div>
                            <div className="email_contents_date_box" style={{width: 'auto', marginLeft: '750px'}}>
                                {/* Empty div for spacing or alignment */}
                            </div>
                            <div className="email_contents_date_box">
                                {/* Another empty div for spacing or alignment */}
                            </div>
                            <div className="game_email_gray_box">
                                <div className="game_email_contents_title_box" style={{height: 'auto', border: 'none'}}>
                                    <button
                                        className="quit_ok_no_button"
                                        style={{position: 'relative', backgroundColor: '#4e4e4e', margin: '72px 0 0 0'}}
                                    >
                                        A
                                    </button>
                                    <div style={{display: 'inline-block', width: '900px', margin: '72px 0 27px 5px', color: "white"}}>
                                        {manToManContent.answer === null ? '답변대기중 입니다. 빠른 시일내에 답변해 드리겠습니다.' : manToManContent.answer}
                                    </div>
                                </div>
                            </div>
                        </div>)}

                        <div className="game_notice_paging_box" style={{justifyContent: 'center'}}>
                            <div className="notice_paging_box_hover" style={{width: '950px', cursor: "pointer", color: "white"}} onClick={() => setShowContent(false)}>
                                <span style={{fontSize: '15px'}}>
                                    목록으로
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>


    );

};

export default InGameManToManContent;
