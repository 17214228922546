import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Header from "./home/header";
import Home from "./home/home";
import Footer from "./home/footer";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Login from "./authentication/login";
import MembershipPage from "./authentication/membership";
import MainGames from "./game/mainGames";
import MembershipCreate from "./authentication/membershipCreate";
import MembershipCreateOk from "./authentication/membershipCreateOk";
import BuyAvatarWeb from "./shop/buyAvatarWeb";
import UpcomingGames from "./game/upcomingGames";
import Shop from "./shop/shop";

import './css/smallScreen.css';
import ServiceCenterPolicyMain from "./servicecenter/serviceCenterPolicyMain";
import MyPageMain from "./mypage/myPageMain";
import GuideDualgo from "./gameGuide/guideDualgo";
import GuideBaduki from "./gameGuide/guideBaduki";
import Guide7Poker from "./gameGuide/guide7Poker";
import MobileUpcomingGames from "./mobile/mUpcomingGames";
import MobileAllGames from "./mobile/mAllGames";
import MobileShop from "./mobile/mShop";
import MobileBuyAvatarWeb from "./mobile/mBuyAvatarWeb";
import MobileQA from "./mobile/mQA";
import DayLimitPopup from "./inGamePopup/dayLimit";
import PaymentRequest from "./payment/paymentRequest";
import PaymentPopup from "./payment/paymentPopup";
import MobileQAGame from "./mobile/mQAGame";
import MobileVerification from "./mobileVerify/mobileVerification";
import MobileLogin from "./mobile/mLogin";
import MobileFienId from "./mobile/mFindId";
import MobileFienPassword from "./mobile/mFindPassword";
import MobileMembership from "./mobile/mMembership";
import MobileMembershipCreate from "./mobile/mMembershipCreate";
import MobileMembershipCreateOK from "./mobile/mMembershipCreateOk";
import MobileTermOfUse from "./mobile/MobileTermOfUse";
import MobilePrivacyPolicy from "./mobile/MobilePrivacyPolicy";
import MobileIndex from "./mobile/mIndex";
import Main from "./main";
import InGameCashBox from "./inGamePopup/cashBox";
import BuyAvatarOk from "./shop/buyAvatarOk";
import InGameFreeCharge from "./inGamePopup/freeCharge";
import InGameWallet from "./inGamePopup/wallet";
import MobileBuyAvatarWebOK from "./mobile/mBuyAvatarWebOK";
import MobileBag from "./mobile/mBag";
import TestingPopup from "./inGamePopup/testingPopup";
import VerifyToken from "./authentication/verifyToken";
import ServiceNotices from "./servicecenter/serviceNotices";
import SearchForHelp from "./servicecenter/SearchForHelp";
import Inquiry from "./servicecenter/inquiry";
import ServiceNoticesContent from "./servicecenter/serviceNoticesContent";
import MobileVerifyRedirect from "./mobileVerify/mobileResponse";
import MobileTesting from "./mobileVerify/mobileTesting";
import MobileTesting2 from "./mobileVerify/mobileTesting2";
import ManToManContent from "./servicecenter/manToManContent";
import MobileShopRedirect from "./mobile/mShopRedirect";
import ShopRedirect from "./shop/shopRedirect";
import BodyColor from "./bodyColor";
import MembershipVerify from "./authentication/membershipVerify";
import MobileMembershipVerify from "./mobile/mMembershipVerify";
import WithdrawInGame from "./mypage/withdrawInGame";
import GameUrl from "./inGame/gameUrl";
import App from "./testing";
import MembershipReconfirm from "./authentication/membershipReconfirm";
import InGameShop from "./inGameShop/shop";
import InGameBuyAvatarOK from "./inGameShop/buyAvatarOk";
import InGameBuyAvatarWeb from "./inGameShop/buyAvatarWeb";
import InGameSearchForHelp from "./inGameServicecenter/SearchForHelp";
import InGameInquiry from "./inGameServicecenter/inquiry";
import InGameServiceCenterPolicyMain from "./inGameServicecenter/serviceCenterPolicyMain";
import InGameManToManContent from "./inGameServicecenter/manToManContent";
import InGameServiceNotices from "./inGameServicecenter/serviceNotices";
import LobbyInGameBuyAvatarWeb from "./inGameShop/lobbybuyAvatarWeb";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <BodyColor />
            <Routes>
                <Route path="/" element={<Main/>}/>

                <Route path="index" element={<><Header/> <Home/> <Footer/></>}/>
                <Route path="login" element={<Login/>}/>
                <Route path="verify_token" element={<VerifyToken/>}/>
                <Route path="membership" element={<MembershipPage/>}/>
                <Route path="membership_verify" element={<MembershipVerify/>}/>
                <Route path="membership_reconfirm" element={<MembershipReconfirm/>}/>
                <Route path="membership_create" element={<MembershipCreate/>}/>
                <Route path="membership_create_ok" element={<MembershipCreateOk/>}/>

                <Route path="game" element={<MainGames/>}/>
                <Route path="buy_avatar_web" element={<BuyAvatarWeb/>}/>
                <Route path="buy_avatar_ok" element={<BuyAvatarOk/>}/>
                <Route path="upcoming_games" element={<UpcomingGames/>}/>
                <Route path="shop" element={<Shop/>}/>
                <Route path="service_center/notice" element={<ServiceNotices/>}/>
                <Route path="service_center/notice_content" element={<ServiceNoticesContent/>}/>
                <Route path="service_center/search_help" element={<SearchForHelp/>}/>
                <Route path="service_center/inquiry" element={<Inquiry/>}/>
                <Route path="service_center/request_content" element={<ManToManContent/>}/>
                <Route path="policy" element={<ServiceCenterPolicyMain/>}/>
                <Route path="my_page" element={<MyPageMain/>}/>
                <Route path="my_page/withdraw_in_game" element={<WithdrawInGame/>}/>
                <Route path="guide_dualgo" element={<GuideDualgo/>}/>
                <Route path="guide_baduki" element={<GuideBaduki/>}/>
                <Route path="guide_7poker" element={<Guide7Poker/>}/>

                <Route path="in_game/game_url" element={<GameUrl/>}/>

                <Route path="pay/payment_request" element={<PaymentRequest/>}/>
                <Route path="pay/payment_popup" element={<PaymentPopup/>}/>
                <Route path="shop_redirect" element={<ShopRedirect/>}/>
                <Route path="mobile_verification" element={<MobileVerification/>}/>

                <Route path="mobile_redirect" element={<MobileVerifyRedirect/>}/>
                <Route path="mobile_testing" element={<MobileTesting/>}/>
                <Route path="mobile_testing_2" element={<MobileTesting2/>}/>

                # mobile
                <Route path="mobile" element={<MobileIndex/>}/>
                <Route path="mobile/login" element={<MobileLogin/>}/>
                <Route path="mobile/find_id" element={<MobileFienId/>}/>
                <Route path="mobile/find_password" element={<MobileFienPassword/>}/>
                <Route path="mobile/membership" element={<MobileMembership/>}/>
                <Route path="mobile/membership_verify" element={<MobileMembershipVerify/>}/>
                <Route path="mobile/membership_create" element={<MobileMembershipCreate/>}/>
                <Route path="mobile/membership_create_ok" element={<MobileMembershipCreateOK/>}/>
                <Route path="mobile/upcoming_games" element={<MobileUpcomingGames/>}/>
                <Route path="mobile/all_games" element={<MobileAllGames/>}/>
                <Route path="mobile/shop" element={<MobileShop/>}/>
                <Route path="mobile/bag" element={<MobileBag/>}/>
                <Route path="mobile/buy_avatar_web" element={<MobileBuyAvatarWeb/>}/>
                <Route path="mobile/buy_avatar_web_ok" element={<MobileBuyAvatarWebOK/>}/>
                <Route path="mobile/qa" element={<MobileQA/>}/>
                <Route path="mobile/qa_game" element={<MobileQAGame/>}/>
                <Route path="mobile/term_of_use" element={<MobileTermOfUse/>}/>
                <Route path="mobile/privacy_policy" element={<MobilePrivacyPolicy/>}/>
                <Route path="mobile/shop_redirect" element={<MobileShopRedirect/>}/>


                # in game
                <Route path="ingame_popup/day_limit" element={<DayLimitPopup/>}/>
                <Route path="ingame_popup/cash_box" element={<InGameCashBox/>}/>
                <Route path="ingame_popup/free_charge" element={<InGameFreeCharge/>}/>
                <Route path="ingame_popup/wallet" element={<InGameWallet/>}/>
                <Route path="ingame_popup/testing" element={<TestingPopup/>}/>

                <Route path="ingame/shop" element={<InGameShop/>}/>
                <Route path="ingame/buy_avatar_web" element={<InGameBuyAvatarWeb/>}/>
                <Route path="ingame/lobby_buy_avatar_web" element={<LobbyInGameBuyAvatarWeb/>}/>
                <Route path="ingame/buy_avatar_ok" element={<InGameBuyAvatarOK/>}/>

                # in game service_center
                <Route path="ingame/service_center/search_help" element={<InGameSearchForHelp/>}/>
                <Route path="ingame/service_center/inquiry" element={<InGameInquiry/>}/>
                <Route path="ingame/policy" element={<InGameServiceCenterPolicyMain/>}/>
                <Route path="ingame/service_center/request_content" element={<InGameManToManContent/>}/>
                <Route path="ingame/service_center/notice" element={<InGameServiceNotices/>}/>

                <Route path="testing" element={<App/>}/>


            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
