import React, {useEffect} from "react";
import $ from 'jquery';
import AuthOperating from "../authentication/operatingPolicy";

const ServiceCenterOperatingPolicy = () => {

    useEffect(() => {
        $('.policy2').css('width', '840px');
        $('.li').css('border', '0.25vw solid white');
    }, []);
  return (
      <div className="game_policy_center_warp">
          <div style={{width: '1000px', background: "#333", color: "#FFFF"}}>
              <AuthOperating/>
          </div>
      </div>

  )
};


export default ServiceCenterOperatingPolicy;