import React, {useEffect, useState} from 'react';
import "../css/serviceCenter.css";
import icon_page_prev from "../images/servicecenter_img/Icon-page_prev.png";
import icon_page_next from "../images/servicecenter_img/Icon-page_next.png";
import axios from "axios";
import baseUrl from "../BaseUrl";
import $ from 'jquery';
import RightGamesWithoutLogin from "../game/rightGamesWithoutLogin";
import SubFooter from "../home/subFooter";
import ServiceCenterHeader from "./serviceCenterHeader";

const getDivisionText = (division) => {

    switch (division) {
        case 0:
            return '[공지]';
        case 1:
            return '[이벤트]';
        case 2:
        case 3:
        case 4:
            return '[점검]';
        default:
            return '';
    }
};


const InGameServiceNoticesContent = ({noticeID, setShowContent}) => {
    const [noticeContent, setNoticeContent] = useState({});
    const [pageIndex, setPageIndex] = useState(1);
    const [messagePopup, setMessagePopup] = useState("");


    const noticeData = async (notice_id) => {
        try {
            const response = await axios.get(baseUrl + 'service_center/notice_content/?notice_id='+notice_id);
            if (response.status === 200) {
                setNoticeContent(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    function onPrevClick() {
        if(noticeContent) {
            if (pageIndex < noticeContent.notice_count){
                setPageIndex(pageIndex + 1);
                noticeData(pageIndex + 1);
            } else {
                setMessagePopup('첫 번째 글 입니다');
            }
        }
    }

    function onNextClick() {
        if (pageIndex > 1){
            setPageIndex(pageIndex - 1);
            noticeData(pageIndex - 1);
        } else {
            setMessagePopup('마지막 글 입니다');
        }
    }


    useEffect(() => {
        noticeData(noticeID);
        setPageIndex(parseInt(noticeID));
    }, [noticeID]);

    return (
        <>
            <div className="game_center_warp_inner">
                <div className="game_sub_main_title_box">
                    <div className="subMaintitle">공지사항</div>
                </div>


                <div className="game_notice_contents_box">
                    <div className="category_title_box">
                        <div
                            className="service_category_title">{getDivisionText(noticeContent.division)}{noticeContent.title}</div>
                    </div>

                    <div className="game_category_box_sub_title">
                        <div className="game_category_box_sub_title_2"
                             dangerouslySetInnerHTML={{__html: noticeContent.contents}}>
                        </div>
                        <div style={{height: '30px', borderBottom: '2px solid rgb(78, 78, 78)'}}/>
                    </div>
                </div>


                <div className="game_notice_paging_box">
                    <div style={{fontSize: '18px', width: '77%', cursor: 'pointer', color: "white"}}
                         onClick={() => setShowContent(false)}>
                        목록으로
                    </div>

                    <div style={{display: "flex"}}>
                        <div
                            className="service_content_paging_button game_service_right_paging"
                            onClick={onPrevClick}
                        >
                            <img src={icon_page_prev} alt=""/>
                            <span style={{color: "white"}}>이전글</span>
                        </div>

                        <div className="service_content_paging_button" style={{paddingLeft: '15px'}}
                             onClick={onNextClick}>
                            <span style={{color: "white"}}>다음글</span>
                            <img src={icon_page_next} alt=""/>
                        </div>
                    </div>

                </div>
            </div>

            {messagePopup && (
                <div className="game_service_center_popup">
                    <div className="game_notice_popup_content">
                        <div className="user_restrict_popup_div_3" style={{marginTop: "35px"}}>
                            {messagePopup}
                        </div>

                        <div className="ok_no_box_button" style={{paddingTop: "20px"}}>
                            <button className="in_game_ok_no_button" style={{marginRight: "unset"}} onClick={() => setMessagePopup('')}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default InGameServiceNoticesContent;
