import "../css/shop.css";
import React, {useEffect, useState} from "react";
import icon_arrow_top from "../images/ingame_arrow_up.svg";
import icon_arrow_bottom from "../images/ingame_arrow_dow.svg";
import $ from 'jquery';
import axios from "axios";
import baseUrl from "../BaseUrl";
import PaymentRequest from "../payment/paymentRequest";
import popup_img from "../images/dualgo_ingame_img/ingame_popupNO.png";
import {useNavigate} from "react-router-dom";


function numberToKorean(number) {
    var avatarQuantity = number < 0 ? false : number;
    var unitWords = ['', '만', '억', '조', '경'];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = '';

    for (var i = 0; i < splitCount; i++) {
        var unitResult = (avatarQuantity % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0) {
            resultArray[i] = unitResult;
        }
    }

    for (var i = 0; i < resultArray.length; i++) {
        if (!resultArray[i]) continue;
        resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
}

function InGameBuyAvatarWeb(props) {
    const navigate = useNavigate();
    const [reward, setReward] = useState('');
    const [avatarQuantity, setAvatarQuantity] = useState(1);
    const [paymentData, setPaymentData] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [monthPayment, setMonthPayment] = useState('');
    const [monthAvailableAmount, setMonthAvailableAmount] = useState('');
    const [totalAmount, setTotalAmount] = useState('10,000');

    const avatarId = localStorage.getItem('avatarId');
    const avatarPrice =  localStorage.getItem('avatarPrice');
    const avatarName =  localStorage.getItem('avatarName');

    const handleInputChange = (event) => {
        let inputValue = event.target.value;

        inputValue = inputValue.replace(/[^0-9\s]/g, '');
        if (inputValue){
            const newValue = Math.min(Math.max(parseInt(inputValue || 0), 1), 70);
            setAvatarQuantity(newValue);

            if (avatarPrice * (newValue) <= monthAvailableAmount) {
                var amount = new Intl.NumberFormat().format(avatarPrice * newValue);
                setTotalAmount(amount);
                setAvatarQuantity(newValue);
            } else {
                setAvatarQuantity(1);
                $("#messagePopup").css('display', 'block');
            }

        } else {
            setAvatarQuantity(inputValue);
        }

    };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    function numberCount(status) {
        if (status === 'up'){
            if (avatarQuantity < 70){
                if (avatarPrice * (avatarQuantity + 1) <= monthAvailableAmount) {
                    var amount = new Intl.NumberFormat().format(avatarPrice * (avatarQuantity + 1));
                    setTotalAmount(amount);
                    setAvatarQuantity(avatarQuantity + 1);
                } else {
                    $("#messagePopup").css('display', 'block');
                }

            }
        } else {
            if (avatarQuantity >= 2){
                var amount = new Intl.NumberFormat().format(avatarPrice * (avatarQuantity - 1));
                setTotalAmount(amount);
                setAvatarQuantity(avatarQuantity - 1);
            }
        }
    }

    const handleSubmit = async () => {
        if (avatarPrice * avatarQuantity <= monthAvailableAmount) {
            var amount = new Intl.NumberFormat().format(avatarPrice * (avatarQuantity));
            setTotalAmount(amount);
            setAvatarQuantity(avatarQuantity);
        } else {
            $("#messagePopup").css('display', 'block');
            return;
        }

        const data = {
            avatarId: avatarId,
            price: avatarPrice,
            // price: 1,
            avatarName: avatarName,
            goPayMethod: paymentMethod,
            avatarQuantity: avatarQuantity,
            inGame: false,
            isMobile: false
        };

        try {
            const response = await axios.post(baseUrl + 'pay/payment_request/', data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
            setPaymentData(response.data);
            localStorage.setItem('avatarCount', avatarQuantity);
        } catch (error) {
            console.error('Error fetching payment data:', error);
        }
    };


    const getMonthPayment = async () => {
        try {
            const response = await axios.get(baseUrl + 'shop/month_payment/', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            setMonthPayment(response.data.month_purchase_amount);
            setMonthAvailableAmount(response.data.available_purchase_amount);

        } catch (error) {
            console.error('Error fetching payment data:', error);
        }
    };

    function closePopup() {
        $("#messagePopup").css('display', 'none');
    }


    useEffect(() => {
        getMonthPayment();

        $('body').css("background-color", "#000000");

        const reward = localStorage.getItem('reward');
        setReward(reward);

    }, []);


    return (
        <>
            <div className="game_shop_avatar_center">
                <div className="GameCenterwarp_inner" style={{height: "auto"}}>

                    <div className="in_game_buy_section">
                        <div className="Buy_contents" style={{backgroundColor: "#000000", color: "white"}}>
                            <div className="Buy_AvartaImg" style={{backgroundColor: "#333"}}>
                                <img src={require('../images/shop_img/' + avatarId + '.gif')} alt="Avatar"/>
                            </div>
                            <div className="Buy_contentsInner">
                                <div className="Buy_TitleBox" style={{borderBottom: '2px solid #4e4e4e', color: "#FFE800"}}>
                                    {avatarName}
                                </div>
                                <div className="ing_game_buy_contentsBox">
                                    <div className="Buy_ContentsTitleBox">수량</div>
                                    <div className="Buy_ContentsTextBox">
                                        <div className="Countnumber" style={{backgroundColor: "#333"}}>
                                            <input
                                                type="text"
                                                id="numberUpDown"
                                                value={avatarQuantity}
                                                onChange={handleInputChange}
                                                style={{color:"white"}}
                                            />
                                        </div>

                                        <div className="game_shop_count_number">
                                            <div onClick={event => numberCount('up')}>
                                                <img className="game_count_number_up_img" src={icon_arrow_top} alt="Increase" style={{backgroundColor: "white"}}/>
                                            </div>

                                            <div onClick={event => numberCount('down')}>
                                                <img className="game_count_number_down_img" src={icon_arrow_bottom}
                                                     alt="Decrease" style={{backgroundColor: "white"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ing_game_buy_contentsBox">
                                    <div className="Buy_ContentsTitleBox">게임머니</div>
                                    <div className="Buy_ContentsTextBox">
                                        <div style={{marginTop: '13px'}}>
                                            <em id="GetGold">{numberToKorean(reward * avatarQuantity)} 골드(냥) 지급</em>
                                        </div>
                                        <input type="hidden" id="PHPreward" value={reward}/>
                                    </div>
                                </div>

                                <div className="ing_game_buy_contentsBox">
                                    <div className="Buy_ContentsTitleBox">결제금액</div>
                                    <div className="Buy_ContentsTextBox">
                                        <div id="BuyPayment"
                                             style={{marginTop: '13px'}}>{new Intl.NumberFormat().format(avatarPrice * avatarQuantity)}원
                                        </div>
                                        <input type="hidden" id="PHPBuyPayment"
                                               value={avatarPrice * avatarQuantity}/>
                                    </div>
                                </div>

                                <div className="ing_game_buy_contentsBox">
                                    <div className="Buy_ContentsTitleBox">결제방법</div>
                                    <div className="buy_contents_box_2" style={{backgroundColor: "#333"}}>
                                        <select
                                            className="game_buy_drop_box_select"
                                            value={paymentMethod}
                                            onChange={handlePaymentMethodChange}
                                        >
                                            <option value="">전체</option>
                                            <option value="HPP">모바일결제</option>
                                            <option value="Vbank">무통장입금</option>
                                            <option value="Card">카드결제</option>
                                            <option value="DirectBank">계좌이체
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div className="BuyMoneyTextBox" style={{backgroundColor: "#333"}}>
                                    <div className="BuyMoneyTextBoxInner"
                                         style={{borderBottom: '2px solid rgb(78, 78, 78)', color:"white"}}>
                                        <div style={{
                                            position: 'absolute',
                                            display: 'inline-block',
                                            marginLeft: '20px'
                                        }}>
                                            이번달 구매 총 금액
                                        </div>
                                        <div style={{
                                            width: '310px',
                                            display: 'inline-block',
                                            textAlign: 'right',
                                            marginLeft: '160px',
                                            color: "white"
                                        }}>
                                            {monthPayment && (monthPayment.toLocaleString())}<em>원</em>
                                        </div>
                                        <div className="BuyMoneyTextBoxInner" style={{marginTop: "9.5px", color:"white"}}>
                                            <div style={{
                                                position: 'absolute',
                                                display: 'inline-block',
                                                marginLeft: '20px'
                                            }}>
                                                추가 구매 가능 금액
                                            </div>
                                            <div style={{
                                                width: '310px',
                                                display: 'inline-block',
                                                textAlign: 'right',
                                                marginLeft: '160px',
                                                color: "white"
                                            }}>
                                                {monthAvailableAmount && (monthAvailableAmount.toLocaleString())}<em>원</em>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "45px",
                                            }}>내지갑에서 사용한 아바타는 환불이 불가능합니다.
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="BuyTotalMoneyBox">
                                    총 상품금액 : <em id="BuyPaymentTotal" style={{
                                    fontSize: '20px',
                                }}>{totalAmount}</em><em
                                    style={{fontSize: '20px'}}>원</em>
                                </div>


                                <div className="quit_oknoBox_button">
                                    <button className="in_game_ok_no_button" type="button" onClick={handleSubmit}>
                                        구매하기
                                        <div className="quit_okno_button_img">
                                        </div>
                                    </button>

                                    <button className="in_game_ok_no_button" type="button" onClick={() => {
                                        navigate(-1);
                                    }} style={{backgroundColor: '#4E4E4E'}}>
                                        취소
                                        <div className="quit_okno_button_img">
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {paymentData && <PaymentRequest paymentData={paymentData} inGame={true}/>}

            <div id="messagePopup" className="shop_message_popup">
                <div className="my_wallet_popup_content">
                    <div className="message-popup-content" style={{marginTop: '0'}}>
                        <img src={popup_img} className="animated shake" alt=""/>
                        <span style={{fontSize: '16px', marginTop: '20px'}}>추가 구매 가능 금액을 초과하였습니다.</span>
                    </div>
                    <div onClick={closePopup} className="message-pass-btn-web">
                        확 인
                    </div>
                </div>
            </div>
        </>
    );
}

export default InGameBuyAvatarWeb;