import React, {useEffect} from "react";
import AuthPrivacyPolicy from "../authentication/privacyPolicy";
import $ from 'jquery';

const ServiceCenterPrivacyPolicy = () => {

    useEffect(() => {
        $('.policy2').css('width', '840px');
    }, []);
  return (
      <div className="game_policy_center_warp">
          <div style={{width: '1000px', background: "#333", color: "#FFFF"}}>
              <AuthPrivacyPolicy/>
          </div>
      </div>

  )
};


export default ServiceCenterPrivacyPolicy;