import React, {useEffect, useState} from "react";
import $ from "jquery";
import ServiceCenterTermsOfUsePolicy from "./serviceCenterTermsOfUsePolicy";
import ServiceCenterPrivacyPolicy from "./serviceCenterPrivacyPolicy";
import ServiceCenterOperatingPolicy from "./serviceCenterOperatingPolicy";
import "../css/inGameServiceCenter.css";

const InGameServiceCenterPolicyMain = () => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');

    const [content, setContent] = useState('');

    function changeTab(e, tabCategory) {
        $('.game_sub_main_title').removeClass('active');

        if(tabCategory === "privacy_policy"){
            $('.game_sub_main_title').eq(1).addClass('active');
            setContent(<ServiceCenterPrivacyPolicy/>);
        }
        else if(tabCategory === 'operating_policy'){
            $('.game_sub_main_title').eq(2).addClass('active');
            setContent(<ServiceCenterOperatingPolicy/>);
        } else {
            $('.game_sub_main_title').eq(0).addClass('active');
            setContent(<ServiceCenterTermsOfUsePolicy/>);
        }
    }

    useEffect(() => {
        $('body').css({
            'background': 'black',
            'zoom': '110%'
        });

        if(page === "privacy_policy"){
            $('.game_sub_main_title').eq(1).addClass('active');
            setContent(<ServiceCenterPrivacyPolicy/>);
        }
        else if(page === 'operating_policy'){
            $('.game_sub_main_title').eq(2).addClass('active');
            setContent(<ServiceCenterOperatingPolicy/>);
        } else {
            $('.game_sub_main_title').eq(0).addClass('active');
            setContent(<ServiceCenterTermsOfUsePolicy/>);
        }

    }, [page]);

    return (
      <>
          <div className="game_service_center_warp">
                <div className="game_service_game_center">
                    <div className="game_center_warp_inner">
                        <div className="game_sub_main_title_box">
                            <div className="game_sub_main_title active" onClick={e => changeTab(e,"terms_of_use")}>이용약관</div>
                            <div className="game_sub_main_title" onClick={e => changeTab(e,"privacy_policy")}>개인정보처리방침</div>
                            <div className="game_sub_main_title" onClick={e => changeTab(e, "operating_policy")}>운영정책</div>
                        </div>

                        {content}
                    </div>
                </div>
          </div>
      </>
  );
};

export default InGameServiceCenterPolicyMain;