import "../css/shop.css";
import React, {useEffect, useState} from "react";
import $ from 'jquery';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import baseUrl from "../BaseUrl";


function numberToKorean(number) {
    var avatarQuantity = number < 0 ? false : number;
    var unitWords = ['', '만', '억', '조', '경'];
    var splitUnit = 10000;
    var splitCount = unitWords.length;
    var resultArray = [];
    var resultString = '';

    for (var i = 0; i < splitCount; i++) {
        var unitResult = (avatarQuantity % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0) {
            resultArray[i] = unitResult;
        }
    }

    for (var i = 0; i < resultArray.length; i++) {
        if (!resultArray[i]) continue;
        resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
}

function InGameBuyAvatarOK() {
    const navigate = useNavigate();
    const [avatarId, setAvatarId] = useState('');
    const [avatarName, setAvatarName] = useState('');
    const [avatarPrice, setAvatarPrice] = useState(0);
    const [reward, setReward] = useState(0);
    const [avatarQuantity, setAvatarQuantity] = useState(1);


    const params = new URLSearchParams(window.location.search);
    var user_id = params.get('user_id');


    const handleInputChange = (event) => {
        let inputValue = event.target.value;

        inputValue = inputValue.replace(/[^0-9\s]/g, '');
        if (inputValue){
            const newValue = Math.min(Math.max(parseInt(inputValue || 0), 1), 70);
            setAvatarQuantity(newValue);
        } else {
            setAvatarQuantity(inputValue);
        }

    };


    const inGameAPI = async () => {
        try {
            await axios.get( `${baseUrl}in_game/shop_wallet_redirect/?user_id=${user_id}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });
        } catch (error) {
            console.error('Error fetching payment data:', error);
        }
    };

    useEffect(() => {
        $('body').css("background-color", "#000000");
        setAvatarId(localStorage.getItem('avatarId'));
        setAvatarName(localStorage.getItem('avatarName'));
        setAvatarPrice(parseInt(localStorage.getItem('avatarPrice')));
        setAvatarQuantity(parseInt(localStorage.getItem('avatarCount')));
        setReward(parseInt(localStorage.getItem('reward')));
    }, []);


    return (
        <>
            <div className="game_shop_avatar_center" >
                <div className="GameCenterwarp_inner" style={{height: "auto"}}>

                        <div className="in_game_buy_section">
                            <div className="Buy_contents" style={{backgroundColor: "#000000", color: "white"}}>
                                <div className="Buy_AvartaImg" style={{backgroundColor: "#333"}}>
                                    <img src={avatarId && require('../images/shop_img/' + avatarId + '.gif')} alt="Avatar"/>
                                </div>
                                <div className="Buy_contentsInner">
                                    <div className="Buy_TitleBox" style={{borderBottom: '2px solid #4e4e4e', color: "#FFE800"}}>
                                        {avatarName}
                                    </div>
                                    <div className="ing_game_buy_contentsBox">
                                        <div className="Buy_ContentsTitleBox">수량</div>
                                        <div className="Buy_ContentsTextBox">
                                            <div className="Countnumber" style={{backgroundColor: "#333"}}>
                                                <input
                                                    type="text"
                                                   id="numberUpDown"
                                                   value={avatarQuantity}
                                                   onChange={handleInputChange}
                                                   style={{color:"white"}}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ing_game_buy_contentsBox">
                                        <div className="Buy_ContentsTitleBox">게임머니</div>
                                        <div className="Buy_ContentsTextBox">
                                            <div style={{marginTop: '13px'}}>
                                                <em id="GetGold">{numberToKorean(reward * avatarQuantity)} 골드(냥) 지급</em>
                                            </div>
                                            <input type="hidden" id="PHPreward" value={reward}/>
                                        </div>
                                    </div>

                                    <div className="ing_game_buy_contentsBox">
                                        <div className="Buy_ContentsTitleBox">결제금액</div>
                                        <div className="Buy_ContentsTextBox">
                                            <div id="BuyPayment"
                                                 style={{marginTop: '13px'}}>{new Intl.NumberFormat().format(parseInt(avatarPrice) * parseInt(avatarQuantity))}원
                                            </div>
                                            <input type="hidden" id="PHPBuyPayment"
                                                   value={avatarPrice * avatarQuantity}/>
                                        </div>
                                    </div>

                                    <div className="BuyMoneyTextBox" style={{backgroundColor: "#333"}}>
                                        <div className="BuyMoneyTextBoxInner" style={{lineHeight: '2'}}>
                                            <div className="BuyMoneyTextBoxInner2">
                                                구매가 완료 되었습니다.<br/>
                                                내지갑에서 사용한 아바타는 환불이 불가능합니다. <br/>
                                                좋은시간 되세요~
                                            </div>
                                        </div>
                                    </div>

                                    <div className="quit_oknoBox_button">
                                        <button className="in_game_ok_no_button" type="button" onClick={() => navigate('/ingame/shop')}>
                                            확인
                                        </button>

                                        <button
                                            className="in_game_ok_no_button"
                                            type="button"
                                            onClick={inGameAPI} style={{backgroundColor: '#4E4E4E'}}
                                        >
                                            내지갑으로
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
            </div>
        </>
    );
}

export default InGameBuyAvatarOK;